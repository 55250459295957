export const getInitials = (name = '') =>
  name
    .split(' ')
    .map(n => n[0])
    .join(' ');
export const removePatientName = (inputString, patientName) => {
  var result = inputString.includes(`- ${patientName}`)
    ? inputString.replace(`- ${patientName}`, '')
    : inputString.replace(`/${patientName}`, '');

  return result;
};
