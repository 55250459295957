import React from 'react';
import { Modal, Form, Input, Button } from 'antd';

const CallHistory = ({
  handleOk,
  handleCancel,
  loading,
  form,
  isModalOpen,
  title,
}) => {
  return (
    <Modal
      title={
        title === 'create' ? 'Add New Call History' : 'Update Call History'
      }
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOk}
        >
          Submit
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="comment"
          label="Comment"
          rules={[
            {
              required: true,
              message: 'Please enter the Comment!',
            },
          ]}
        >
          <Input placeholder="Enter something" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CallHistory;
