import NumberFormat from 'react-number-format';

import { CRTable } from 'components';
import { getUnitOfMeasureShortCut } from 'services/inventory';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';

const ListInventory = ({ items, totalCostOfItems }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <CRTable autoHeight data={items}>
        <CRTable.CRColumn width={50}>
          <CRTable.CRHeaderCell>{t('number')}</CRTable.CRHeaderCell>
          <CRTable.CRCell>
            {({ name }, index) => (
              <CRTable.CRCellStyled>{index + 1}</CRTable.CRCellStyled>
            )}
          </CRTable.CRCell>
        </CRTable.CRColumn>
        <CRTable.CRColumn width={200}>
          <CRTable.CRHeaderCell>{t('name')}</CRTable.CRHeaderCell>
          <CRTable.CRCell>
            {({ name }) => <CRTable.CRCellStyled>{name}</CRTable.CRCellStyled>}
          </CRTable.CRCell>
        </CRTable.CRColumn>

        <CRTable.CRColumn width={200}>
          <CRTable.CRHeaderCell>{t('numberOfBoxes')}</CRTable.CRHeaderCell>
          <CRTable.CRCell dataKey="amount" semiBold>
            {({ amount }) => (
              <CRTable.CRCellStyled semiBold>
                <NumberFormat
                  value={amount}
                  displayType="text"
                  thousandSeparator
                />
              </CRTable.CRCellStyled>
            )}
          </CRTable.CRCell>
        </CRTable.CRColumn>

        <CRTable.CRColumn width={200}>
          <CRTable.CRHeaderCell>{t('uintsQuantity')}</CRTable.CRHeaderCell>
          <CRTable.CRCell>
            {({ quantity, item }) => (
              <CRTable.CRCellStyled semiBold>
                <NumberFormat
                  value={quantity}
                  displayType="text"
                  thousandSeparator
                />
                <span display="inline" ml={2}>
                  {getUnitOfMeasureShortCut(item.unitOfMeasure)}
                </span>
              </CRTable.CRCellStyled>
            )}
          </CRTable.CRCell>
        </CRTable.CRColumn>
        <CRTable.CRColumn flexGrow={1}>
          <CRTable.CRHeaderCell>{t('totalCostOfUnits')}</CRTable.CRHeaderCell>
          <CRTable.CRCell>
            {({ totalCost }) => (
              <CRTable.CRCellStyled>{totalCost}</CRTable.CRCellStyled>
            )}
          </CRTable.CRCell>
        </CRTable.CRColumn>
        <CRTable.CRColumn width={200}>
          <CRTable.CRHeaderCell>{t('branch')}</CRTable.CRHeaderCell>
          <CRTable.CRCell>
            {({ branch }) => (
              <CRTable.CRCellStyled>
                {branch?.name ? branch?.name : 'Organization warehouse'}
              </CRTable.CRCellStyled>
            )}
          </CRTable.CRCell>
        </CRTable.CRColumn>
      </CRTable>
      <div className="bg-black p-5 font-bold text-white w-full">
        Total Cost : {totalCostOfItems}
      </div>{' '}
    </Fragment>
  );
};

export default ListInventory;
