import React, { memo, useEffect, useState } from 'react';
import { CRTextInput, Div } from 'components';
import { Checkbox, CheckboxGroup, Form } from 'rsuite';

const initialValue = {
  text: '',
};
const CheckWithInput = ({ choices, onChange, value, disabled, ...props }) => {
  const [formValue, setFormValue] = useState(initialValue);
  const [checkedValue, setCheckedValue] = useState([]);
  useEffect(() => {
    onChange({ text: formValue?.text, choices: checkedValue });
  }, [formValue.text, checkedValue]);
  useEffect(() => {
    setFormValue(value);
    setCheckedValue(value?.choices);
  }, []);
  return (
    <Div maxWidth={600}>
      <Form formValue={formValue} onChange={setFormValue}>
        <Div display="flex" justifyContent="space-between">
          <Div m="10px">
            <CheckboxGroup
              inline
              name="choices"
              onChange={setCheckedValue}
              value={checkedValue}
            >
              {(choices || []).map(c => (
                <Checkbox value={c.id}>{c.name}</Checkbox>
              ))}
            </CheckboxGroup>
          </Div>
          <Div flexGrow={1}>
            <CRTextInput name="text" disabled={disabled} />
          </Div>
        </Div>
      </Form>
    </Div>
  );
};

export default memo(CheckWithInput);
