import { Table, Tooltip, Button, Popconfirm, Form } from 'antd';
import { usePatients } from 'hooks';
import { Fragment, useCallback, useState } from 'react';
import { formatFullDay } from 'utils/date';
import Filter from './filter';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import CallHistoryModal from './call-history';

const CallHistory = ({ patient }) => {
  const [search, setSearch] = useState('');
  const [type, setType] = useState('');
  const [pagination] = useState(1);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(null);
  const {
    callHistory,
    callHistoryLoading,
    deleteCallHistory,
    createCallHistory,
  } = usePatients({
    patientId: patient.id,
    name: search,
  });
  const handleDeleteCallHistory = id => {
    deleteCallHistory({ variables: { id } });
  };

  const handleOk = useCallback(() => {
    form
      .validateFields()
      .then(values => {
        setLoading(true);
        createCallHistory({
          variables: {
            patientId: patient.id,
            comment: values.comment,
            id: id,
          },
        })
          .then(res => {
            setLoading(false);
            setId(null);
            setIsModalOpen(false);
          })
          .catch(err => console.log(err));
      })
      .catch(errorInfo => {
        console.log('Validation failed:', errorInfo);
      });
  }, [id, createCallHistory, form, patient.id]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleEditCallHistory = elm => {
    form.setFieldsValue(elm);
    setId(elm.id);
    showModal();
    setType('update');
  };
  const columns = [
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, record) => {
        return formatFullDay(text);
      },
    },
    {
      title: 'User Name',
      dataIndex: 'user',
      key: 'user',
      render: (text, record) => {
        return text.name;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '200px',
      render: (_, elm) => (
        <div className="text-right">
          <Tooltip title="Edit">
            <Button
              type="primary"
              className="mr-2"
              icon={<EditOutlined />}
              onClick={() => {
                handleEditCallHistory(elm);
              }}
              size="small"
            />
          </Tooltip>

          <Popconfirm
            placement="top"
            title="Are you sure ?"
            onConfirm={() => {
              handleDeleteCallHistory(elm.id);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete">
              <Button danger icon={<DeleteOutlined />} size="small" />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];
  const onSearch = (value, _e, info) => setSearch(value);
  const createNewCallHistory = () => {
    setIsModalOpen(true);
    setType('create');
  };
  return (
    <Fragment>
      <Filter onSearch={onSearch} createCallHistory={createNewCallHistory} />
      <Table
        columns={columns}
        dataSource={callHistory}
        pagination={pagination}
        loading={callHistoryLoading}
      />
      <CallHistoryModal
        handleOk={handleOk}
        handleCancel={handleCancel}
        loading={loading}
        form={form}
        isModalOpen={isModalOpen}
        title={type}
      />
    </Fragment>
  );
};
export default CallHistory;
