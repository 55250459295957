import React, { useState, useEffect, useMemo } from 'react';
import { Form, Schema } from 'rsuite';

import { CRTextInput, CRModal, CRCheckBoxGroup, H3 } from 'components';
import { CRSelectInput } from 'components/widgets';
import { POSITIONS } from 'utils/constants';
import { mapObjValuesToChoices } from 'utils/misc';
import { useTranslation } from 'react-i18next';

export default function NewUser({
  show,
  onCancel,
  onCreate,
  specialties,
  onOk,
  formValue,
  onChange,
  type,
  checkResult,
  validate,
  showError,
  setShow,
}) {
  const { t } = useTranslation();
  const header = useMemo(() => {
    if (type === 'create') {
      return t('createNewUser');
    } else {
      return t('editUser');
    }
  }, [type]);
  return (
    <CRModal
      show={show}
      header={header}
      onHide={onCancel}
      onCancel={onCancel}
      onOk={() => {
        setShow(true);
        validate && onOk(formValue);
      }}
    >
      <Form fluid formValue={formValue} onChange={onChange}>
        {type === 'delete' ? (
          <H3>Are you sure that you want delete user and all related data?</H3>
        ) : (
          <>
            <CRTextInput
              label={t('name')}
              name="name"
              errorMessage={
                showError && checkResult['name'].hasError
                  ? checkResult['name'].errorMessage
                  : ''
              }
            />
            <CRTextInput
              label={t('email')}
              name="email"
              errorMessage={
                showError && checkResult['email'].hasError
                  ? checkResult['email'].errorMessage
                  : ''
              }
            />
            <CRTextInput
              label={t('password')}
              name="password"
              type="password"
              errorMessage={
                showError && checkResult['password'].hasError
                  ? checkResult['password'].errorMessage
                  : ''
              }
            />
          </>
        )}
      </Form>
    </CRModal>
  );
}

NewUser.propTypes = {};

NewUser.defaultProps = {
  specialties: [],
};
