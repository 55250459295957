import React from 'react';
import { BranchSpecialtyUserFilter, Div } from 'components';
import { useBranchTree } from 'hooks';
import { ACTIONS } from 'utils/constants';

export default function CalendarFilter({
  formValue,
  onChange,
  doctors,
  branches,
}) {
  const { filterBranches } = useBranchTree({ action: ACTIONS.View_Calendar });

  return (
    <Div my={2}>
      <BranchSpecialtyUserFilter
        formValue={formValue}
        onChange={onChange}
        branches={filterBranches}
      />
    </Div>
  );
}
