import { CRSelectInput, Div, CRDateRangePicker } from 'components';
import { Form } from 'rsuite';
import styled from 'styled-components';
const StyledForm = styled(Form)`
  margin: 2px 0px 7px 0px;
  display: flex;
  @media only screen and (max-width: 600px) {
    display: block;
  }
`;

const Filter = ({ formValue, setFormValue, setPeriod, t, items }) => {
  return (
    <StyledForm
      formValue={formValue}
      onChange={setFormValue}
      className="mt-2 mb-7 flex"
    >
      <CRSelectInput
        label={t('item')}
        name="itemId"
        data={items}
        valueKey="id"
        labelKey="name"
        style={{ width: '260px', marginRight: '10px' }}
      />
      <Div width={256}>
        <Form fluid>
          <CRDateRangePicker
            name=""
            label="Date from-to"
            placeholder={t('timeframe')}
            size="sm"
            block
            small
            $noLabel
            onChange={setPeriod}
            placement="auto"
          />
        </Form>
      </Div>
    </StyledForm>
  );
};
export default Filter;
