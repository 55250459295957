import { useMemo } from 'react';
import * as R from 'ramda';
import { useQuery, useMutation } from '@apollo/client';
import { APPT_TYPE } from 'utils/constants';
import {
  LIST_APPOINTMENTS,
  APPOINTMENTS_DAY_COUNT,
  ARCHIVE_APPOINTMENT,
  COMPLETE_APPOINTMENT,
  LIST_REVENUES,
  LIST_EXPENSES,
  LIST_INVENTORY,
  LIST_INVENTORY_HISTORY,
  UPDATE_BUSINESS_NOTES,
  PATIENT_COUPONS,
  ADJUST_APPOINTMENT,
  CANCEL_APPOINTMENT,
  GET_INVOICE_COUNTER,
  DELETE_APPOINTMENT_PHOTO,
  GET_APPOINTMENT_HISTORY,
  CONFIRMED_APPOINTMENT,
  TRANSFER_APPOINTMENTS,
  ARCHIVE_REFERED_DOCTORAPPOINTMENT,
  GET_PATIENT,
  ARRIVED_APPOINTMENT,
} from 'apollo-client/queries';

import client from 'apollo-client/client';
import { Alert } from 'rsuite';
import useAuth from './auth';

const updateAppointmentsCache = ({ appointments, appointmentsCount }) => {
  client.writeQuery({
    query: LIST_APPOINTMENTS,
    data: {
      appointments: {
        appointments,
        appointmentsCount,
      },
    },
  });
};

function useAppointments({
  includeSurgery,
  page,
  dateFrom,
  dateTo,
  patient,
  specialtyId,
  branchId,
  doctorId,
  type,
  status,
  date,
  userId,
  patientId,
  onDeletePhoto,
  setFollowUp,
  setPopUp,
  open,
  followUpFeature,
  canAddFollowUp,
  setAppointment,
  onArchive,
  roomId,
} = {}) {
  const { getUserForResource } = useAuth();
  const permssionDoctorId =
    doctorId || getUserForResource('List', 'Appointment');

  const { data, refetch: refetchAppointments } = useQuery(LIST_APPOINTMENTS, {
    fetchPolicy: 'cache-and-network',
    variables: Object.assign(
      {
        offset: (page - 1) * 20 || 0,
        limit: 20,
        status,
      },
      patient && { patient: patient },
      dateFrom && { dateFrom: new Date(dateFrom) },
      dateTo && { dateTo: new Date(dateTo) },
      type && type !== 'null' && { type },
      branchId && { branchId: branchId },
      specialtyId && { specialtyId: specialtyId },
      permssionDoctorId && { doctorId: permssionDoctorId }
    ),
  });

  const appointmentsdata = data?.appointments;
  const appointmentsCountNumber = R.propOr(
    [],
    'appointmentsCount'
  )(appointmentsdata);

  const appointments = useMemo(
    () =>
      R.pipe(
        R.propOr([], 'appointments'),
        includeSurgery
          ? R.identity
          : R.reject(R.propEq('type', APPT_TYPE.Surgery))
      )(appointmentsdata),
    [appointmentsdata, includeSurgery]
  );

  const pages = Math.ceil(appointmentsCountNumber / 20);
  const { data: appointmentsDay } = useQuery(APPOINTMENTS_DAY_COUNT, {
    variables: {
      date: date,
      userId: userId,
      roomId,
    },
    fetchPolicy: 'cache-and-network',
  });
  const appointmentsCount = useMemo(
    () => R.propOr({}, 'appointmentsDayCount')(appointmentsDay),
    [appointmentsDay]
  );

  const specialties = useMemo(
    () => R.pipe(R.propOr([], 'specialties'))(data),
    [data]
  );

  const doctors = useMemo(() => R.pipe(R.propOr([], 'doctors'))(data), [data]);

  const [archive, { loading: archiveLoading }] = useMutation(
    ARCHIVE_APPOINTMENT,
    {
      onCompleted: () => {
        Alert.success('Appointment has been Archived successfully');
        onArchive && onArchive();
        refetchAppointments();
        if (followUpFeature && canAddFollowUp) {
          setFollowUp(true);
          setPopUp('followUpAppointment');
          open();
        }
      },
      refetchQueries: [
        {
          query: LIST_REVENUES,
        },
        {
          query: LIST_EXPENSES,
        },
        {
          query: PATIENT_COUPONS,
          variables: { patientId: patientId, all: false },
        },
        { query: GET_PATIENT, variables: { id: patientId } },
        { query: LIST_INVENTORY },
        { query: LIST_INVENTORY_HISTORY },
        {
          query: GET_INVOICE_COUNTER,
        },
      ],
      onError: ({ message }) => Alert.error(message),
    }
  );
  const [archiveReferedDoctorAppointment] = useMutation(
    ARCHIVE_REFERED_DOCTORAPPOINTMENT,
    {
      onCompleted: () => {
        Alert.success('Appointment has been Archived successfully');
        onArchive && onArchive();
      },
      onError: ({ message }) => Alert.error(message),
    }
  );
  const [complete] = useMutation(COMPLETE_APPOINTMENT, {
    onCompleted: () => {
      Alert.success('Appointment has been Completed successfully');
      setAppointment({});
      refetchAppointments();
    },
  });
  const [updateNotes] = useMutation(UPDATE_BUSINESS_NOTES, {
    onCompleted: () => {
      Alert.success('Business Notes Added Successfully');
    },

    update(cache, { data: { updateNotes: appointment } }) {
      const app = appointments.find(a => a.id === appointment.id);
      const newApp = { ...app, businessNotes: appointment.businessNotes };
      const allNewApp = appointments.map(oldApp => {
        if (oldApp.id === appointment.id) {
          return newApp;
        } else {
          return oldApp;
        }
      });
      updateAppointmentsCache(allNewApp);
    },
  });
  const [adjust] = useMutation(ADJUST_APPOINTMENT, {
    onCompleted: () => {
      Alert.success('Appointment has been changed successfully');
      refetchAppointments();
    },
    refetchQueries: [
      {
        query: LIST_APPOINTMENTS,
      },
    ],
  });
  const [confirmedAppointment] = useMutation(CONFIRMED_APPOINTMENT, {
    onCompleted: ({ confirmedAppointment: { id } }) => {
      const newAppointments = R.map(appointment =>
        id === appointment.id
          ? { ...appointment, confirmed: !appointment.confirmed }
          : appointment
      )(appointments);
      updateAppointmentsCache({
        appointments: newAppointments,
        appointmentsCount,
      });
    },
  });

  const [arrivedAppointment] = useMutation(ARRIVED_APPOINTMENT, {
    onCompleted: ({ arrivedAppointment: { id } }) => {
      const newAppointments = R.map(appointment =>
        id === appointment.id
          ? { ...appointment, arrived: !appointment.arrived }
          : appointment
      )(appointments);
      updateAppointmentsCache({
        appointments: newAppointments,
        appointmentsCount,
      });
    },
  });

  const [cancel] = useMutation(CANCEL_APPOINTMENT, {
    onCompleted: () => {
      Alert.success('Appointment has been cancelled successfully');
      refetchAppointments();
    },
    refetchQueries: [
      {
        query: LIST_APPOINTMENTS,
      },
    ],
  });
  const [transferAppointments] = useMutation(TRANSFER_APPOINTMENTS, {
    onCompleted: () => {
      Alert.success('Appointments has been Transfered successfully');
      refetchAppointments();
    },
  });
  const [deleteAppointmentPhoto] = useMutation(DELETE_APPOINTMENT_PHOTO, {
    onCompleted: () => {
      Alert.success('Appointment Photo has been Deleted successfully');
      onDeletePhoto && onDeletePhoto();
    },
    refetchQueries: [
      {
        query: LIST_APPOINTMENTS,
      },
      {
        query: GET_APPOINTMENT_HISTORY,
        variables: { patientId: patientId, type: type },
      },
    ],
  });

  return useMemo(
    () => ({
      appointments,
      appointmentsCount,
      deleteAppointmentPhoto,
      confirmedAppointment,
      refetchAppointments: {
        query: LIST_APPOINTMENTS,
      },
      branches: [],
      specialties,
      pages,
      doctors,
      archive,
      complete,
      archiveLoading,
      updateNotes,
      adjust,
      cancel,
      transferAppointments,
      archiveReferedDoctorAppointment,
      arrivedAppointment,
    }),
    [
      appointments,
      appointmentsCount,
      specialties,
      confirmedAppointment,
      deleteAppointmentPhoto,
      pages,
      doctors,
      archive,
      complete,
      archiveLoading,
      updateNotes,
      adjust,
      cancel,
      transferAppointments,
      archiveReferedDoctorAppointment,
      arrivedAppointment,
    ]
  );
}

export default useAppointments;
