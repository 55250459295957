import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStore } from 'common/interfaces';
import { RootState } from 'redux-store/store';
import {
  SELECTED_BRANCH,
  SELECTED_SPECIALTY,
  SELECTED_DOCTOR,
  SELECTED_PATIENT,
  SELECTED_SESSION_TYPE,
  SELECTED_DATE_PICKER_ONE,
  SELECTED_DATE_PICKER_TWO,
  SELECTED_BRANCH_ALL,
  SELECTED_SPECIALTY_ALL,
  SELECTED_DOCTOR_ALL,
} from 'utils/constants';
import { formatDate } from 'utils/date';

const initialState: RootStore = {
  selectedBranch: localStorage.getItem(SELECTED_BRANCH),
  selectedSpecialty: localStorage.getItem(SELECTED_SPECIALTY),
  selectedDoctor: localStorage.getItem(SELECTED_DOCTOR),
  selectedPatient: localStorage.getItem(SELECTED_PATIENT),
  selectedSessionType: localStorage.getItem(SELECTED_SESSION_TYPE),
  selectedDatePicker:
    localStorage.getItem(SELECTED_DATE_PICKER_ONE) ===
      localStorage.getItem(SELECTED_DATE_PICKER_TWO) &&
    localStorage.getItem(SELECTED_DATE_PICKER_TWO) ===
      formatDate(new Date(), 'YYYY/MM/DD')
      ? []
      : [
          formatDate(
            localStorage.getItem(SELECTED_DATE_PICKER_ONE),
            'YYYY/MM/DD'
          ),
          formatDate(
            localStorage.getItem(SELECTED_DATE_PICKER_TWO),
            'YYYY/MM/DD'
          ),
        ],
  selectedAllAppBranch: localStorage.getItem(SELECTED_BRANCH_ALL),
  selectedAllAppSpecialty: localStorage.getItem(SELECTED_SPECIALTY_ALL),
  selectedAllAppDoctor: localStorage.getItem(SELECTED_DOCTOR_ALL),
};
const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    setSelectedBranch: (state, action: PayloadAction<string | null>) => {
      state.selectedBranch = action.payload;
    },
    setSelectedSpecialty: (state, action: PayloadAction<string | null>) => {
      state.selectedSpecialty = action.payload;
    },
    setSelectedDoctor: (state, action: PayloadAction<string | null>) => {
      state.selectedDoctor = action.payload;
    },
    setSelectedPatient: (state, action: PayloadAction<string | null>) => {
      state.selectedPatient = action.payload;
    },
    setSelectedSessionType: (state, action: PayloadAction<string | null>) => {
      state.selectedSessionType = action.payload;
    },
    setSelectedDatePicker: (
      state,
      action: PayloadAction<Array<Date> | null>
    ) => {
      state.selectedDatePicker = action.payload;
    },
    setSelectedAllAppBranch: (state, action: PayloadAction<string | null>) => {
      state.selectedAllAppBranch = action.payload;
    },
    setSelectedAllAppSpecialty: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.selectedAllAppSpecialty = action.payload;
    },
    setSelectedAllAppDoctor: (state, action: PayloadAction<string | null>) => {
      state.selectedAllAppDoctor = action.payload;
    },
  },
});

export default rootSlice.reducer;

export const {
  setSelectedBranch,
  setSelectedSpecialty,
  setSelectedDoctor,
  setSelectedPatient,
  setSelectedSessionType,
  setSelectedDatePicker,
  setSelectedAllAppBranch,
  setSelectedAllAppSpecialty,
  setSelectedAllAppDoctor,
} = rootSlice.actions;

export const selectSelectedBranch = (state: RootState) =>
  state.root.selectedBranch;
export const selectSelectedSpecialty = (state: RootState) =>
  state.root.selectedSpecialty;
export const selectSelectedDoctor = (state: RootState) =>
  state.root.selectedDoctor;
export const selectSelectedPatient = (state: RootState) =>
  state.root.selectedPatient;
export const selectSelectedSessionType = (state: RootState) =>
  state.root.selectedSessionType;
export const selectSelectedDatePicker = (state: RootState) =>
  state.root.selectedDatePicker;
export const selectSelectedAllAppBranch = (state: RootState) =>
  state.root.selectedAllAppBranch;
export const selectSelectedAllAppSpecialty = (state: RootState) =>
  state.root.selectedAllAppSpecialty;
export const selectSelectedAllAppDoctor = (state: RootState) =>
  state.root.selectedAllAppDoctor;
