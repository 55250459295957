import React, { memo, useEffect, useState } from 'react';
import { CRTextInput, Div } from 'components';
import { Form, Radio, RadioGroup } from 'rsuite';

const initialValue = {
  choice: '',
  text: '',
};
const RadioWithInput = ({ choices, onChange, value, disabled, ...props }) => {
  const [formValue, setFormValue] = useState(initialValue);
  const [radioValue, setRadioValue] = useState();
  useEffect(() => {
    setFormValue(value);
    setRadioValue(value?.choice);
  }, []);
  useEffect(() => {
    onChange({ text: formValue?.text, choice: radioValue });
  }, [radioValue, formValue?.text, onChange]);
  return (
    <Div maxWidth={600}>
      <Form formValue={formValue} onChange={setFormValue}>
        <Div display="flex" justifyContent="space-between">
          <Div m="10px">
            <RadioGroup
              inline
              name="choice"
              value={radioValue}
              onChange={setRadioValue}
            >
              {(choices || []).map(c => (
                <Radio value={c.id}>{c.name}</Radio>
              ))}
            </RadioGroup>
          </Div>
          <Div flexGrow={1}>
            <CRTextInput name="text" disabled={disabled} />
          </Div>
        </Div>
      </Form>
    </Div>
  );
};

export default memo(RadioWithInput);
