import { MainContainer, Div, CRButton } from 'components';
import React, { useCallback, useState } from 'react';
import * as R from 'ramda';
import { Users, AddDoctor } from 'components';
import NewUser from './create-new-user';
import { usePermissions, useModal, useForm } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Schema } from 'rsuite';
const { StringType } = Schema.Types;

const model = Schema.Model({
  name: StringType().isRequired('Name is required'),
  email: StringType()
    .isEmail('Please enter a valid email address.')
    .isRequired('Email is required'),
  password: StringType().isRequired('Password is required'),
});

const initialValues = {
  name: '',
  email: '',
  password: '',
  position: '',
  allowedViews: [],
};
const NewUserComponent = () => {
  const { visible, open, close } = useModal();
  const { formValue, setFormValue, checkResult, validate, show, setShow } =
    useForm({
      initValue: initialValues,
      model,
    });
  const {
    visible: userVisible,
    open: openUser,
    close: closeDoctor,
  } = useModal();
  const [type, setType] = useState('');
  const {
    users,
    createUser,
    editUser,
    deleteUser,
    addDoctor,
    branches,
    specialties,
    doctors,
  } = usePermissions({
    onCreateUser: close,
    onEditUser: close,
    onAddDoctor: closeDoctor,
  });
  const handleClickCreateDoctor = useCallback(() => {
    setType('create');
    setFormValue({ ...initialValues, position: 'Doctor' });
    open();
  }, [open, setType, setFormValue]);
  const handleCreateAssistant = useCallback(() => {
    setType('create');
    setFormValue({ ...initialValues, position: 'Assistant' });
    open();
  }, [open, setType, setFormValue]);
  const handleClickEdit = useCallback(
    data => {
      const user = R.pick(['id', 'name', 'email', 'allowedViews'])(data);
      setType('edit');
      setFormValue(user);
      open();
    },
    [open, setFormValue, setType]
  );
  const handleClickDelete = useCallback(
    data => {
      const user = R.pick(['id'])(data);
      setType('delete');
      setFormValue(user);
      open();
    },
    [open, setFormValue, setType]
  );
  const handleAdd = useCallback(() => {
    if (type === 'create') {
      createUser(formValue);
    } else if (type === 'edit') {
      editUser(formValue);
    } else {
      deleteUser(formValue);
    }
  }, [createUser, editUser, deleteUser, formValue, type]);
  const handleAddDoctor = useCallback(
    specialty => {
      addDoctor(specialty);
    },
    [addDoctor]
  );
  return (
    <>
      <MainContainer
        title="Users"
        nobody
        more={
          <Div>
            <CRButton
              variant="primary"
              mt={2}
              mr={2}
              onClick={handleCreateAssistant}
            >
              New Assistant
            </CRButton>
            <CRButton
              variant="primary"
              mt={2}
              mr={2}
              onClick={handleClickCreateDoctor}
            >
              New Doctor
            </CRButton>
            <CRButton variant="primary" mt={2} mr={2} onClick={openUser}>
              Add Doctor to Specialty
            </CRButton>
          </Div>
        }
      ></MainContainer>
      <NewUser
        show={visible}
        onHide={close}
        onCancel={close}
        onOk={handleAdd}
        formValue={formValue}
        onChange={setFormValue}
        type={type}
        checkResult={checkResult}
        validate={validate}
        showError={show}
        setShow={setShow}
      />
      <AddDoctor
        onCreate={handleAddDoctor}
        show={userVisible}
        onHide={closeDoctor}
        onCancel={closeDoctor}
        specialties={specialties}
        doctors={doctors}
      />
      <Users
        users={users}
        onEdit={handleClickEdit}
        onDelete={handleClickDelete}
      />
    </>
  );
};
export default NewUserComponent;
