import { Button, Col, Input, Row } from 'antd';
const { Search } = Input;

const Filter = ({ onSearch, createCallHistory }) => {
  return (
    <Row className="mb-4">
      <Col xs={14} sm={12} md={20}>
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ maxWidth: 400 }}
        />
      </Col>
      <Col xs={10} sm={12} md={4}>
        <Button type="primary" onClick={() => createCallHistory()}>
          Add New Call History
        </Button>
      </Col>
    </Row>
  );
};
export default Filter;
