import { CRTable, CRCard } from 'components';
import { formatFullDay } from 'utils/date';
import { useCallback } from 'react';
const ListData = ({ data, t, setCurrentPage, currentPage, pages }) => {
  const handleSelect = useCallback(
    eventKey => {
      setCurrentPage({ activePage: eventKey });
    },
    [setCurrentPage]
  );
  return (
    <CRCard borderless>
      <CRTable data={data} autoHeight>
        <CRTable.CRColumn width={200}>
          <CRTable.CRHeaderCell>{t('date')}</CRTable.CRHeaderCell>
          <CRTable.CRCell>
            {({ date }) => (
              <CRTable.CRCellStyled bold>
                {formatFullDay(date)}
              </CRTable.CRCellStyled>
            )}
          </CRTable.CRCell>
        </CRTable.CRColumn>

        <CRTable.CRColumn width={1000}>
          <CRTable.CRHeaderCell>{t('body')}</CRTable.CRHeaderCell>
          <CRTable.CRCell>
            {({ body }) => (
              <CRTable.CRCellStyled bold>{body}</CRTable.CRCellStyled>
            )}
          </CRTable.CRCell>
        </CRTable.CRColumn>
      </CRTable>
      <CRTable.CRPagination
        lengthMenu={[
          {
            value: 10,
            label: 10,
          },
          {
            value: 20,
            label: 20,
          },
        ]}
        activePage={currentPage?.activePage}
        pages={pages}
        onSelect={handleSelect}
      />
    </CRCard>
  );
};
export default ListData;
