import { CRDateRangePicker, CRButton, MenuPopover } from 'components';
import { Form, Whisper } from 'rsuite';
import ReportRow from '../report-row';
import { Name } from '../style';
const PulsesReport = ({
  t,
  handlePulsesPdfReport,
  formValue,
  setFormValue,
  handlePulsesExcelReport,
}) => {
  function handleSelectMenu(eventKey, event) {
    eventKey === 1 ? handlePulsesPdfReport() : handlePulsesExcelReport();
  }
  return (
    <ReportRow>
      <Name>{t('PulsesReport')}</Name>
      <Form
        formValue={formValue}
        onChange={setFormValue}
        className="inline-flex flex-wrap items-center"
      >
        <CRDateRangePicker
          name="pulsesDate"
          placeholder={t('timeframe')}
          placement="auto"
          style={{ width: '230px', marginRight: '30px' }}
        />
      </Form>
      <Whisper
        placement="bottomStart"
        trigger="click"
        speaker={<MenuPopover onSelect={handleSelectMenu} />}
      >
        <CRButton mt="10px">Print</CRButton>
      </Whisper>
    </ReportRow>
  );
};
export default PulsesReport;
