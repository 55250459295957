import React from 'react';
import { Icon } from 'rsuite';
import { CRCard, CRTable } from 'components';
import { useTranslation } from 'react-i18next';

function ListAppointmentTypes({
  appointmentTypes,
  setAppointmentType,
  setShowAppointmentTypeData,
  onEdit,
  onDelete,
}) {
  const { t } = useTranslation();
  return (
    <>
      <CRCard borderless>
        <CRTable
          autoHeight
          data={appointmentTypes}
          // onRowClick={appointmentType => {
          //   setAppointmentType(appointmentType);
          //   setShowAppointmentTypeData(true);
          // }}
        >
          <CRTable.CRColumn flexGrow={1}>
            <CRTable.CRHeaderCell>{t('name')}</CRTable.CRHeaderCell>
            <CRTable.CRCell>
              {({ name }) => (
                <CRTable.CRCellStyled bold>{name}</CRTable.CRCellStyled>
              )}
            </CRTable.CRCell>
          </CRTable.CRColumn>
          <CRTable.CRColumn flexGrow={1}>
            <CRTable.CRHeaderCell>{t('urgent')}</CRTable.CRHeaderCell>
            <CRTable.CRCell>
              {({ urgent }) => (
                <CRTable.CRCellStyled bold>
                  {urgent ? 'Yes' : 'No'}
                </CRTable.CRCellStyled>
              )}
            </CRTable.CRCell>
          </CRTable.CRColumn>
          <CRTable.CRColumn>
            <CRTable.CRHeaderCell></CRTable.CRHeaderCell>
            <CRTable.CRCell>
              {data => (
                <CRTable.CRCellStyled bold>
                  <Icon icon="edit" onClick={() => onEdit(data)}>
                    {' '}
                    {t('edit')}
                  </Icon>
                </CRTable.CRCellStyled>
              )}
            </CRTable.CRCell>
          </CRTable.CRColumn>
          <CRTable.CRColumn>
            <CRTable.CRHeaderCell></CRTable.CRHeaderCell>
            <CRTable.CRCell>
              {data => (
                <Icon icon="trash" onClick={() => onDelete(data)}>
                  {' '}
                  {t('delete')}
                </Icon>
              )}
            </CRTable.CRCell>
          </CRTable.CRColumn>
        </CRTable>
      </CRCard>
    </>
  );
}

export default ListAppointmentTypes;
