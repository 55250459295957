export const initValue = {
  sessions: [],
  items: [],
};
export const initlOption = {
  option: '',
  amount: 0,
  payMethod: 'cash',
};

export const initInventoryValue = {
  itemId: null,
  quantity: 1,
  branchId: null,
  specialtyId: null,
  userId: null,
};
export const companyInital = {
  companyId: null,
  cardId: '',
  cardExpiryDate: null,
  paymentMethod: 'cash',
  bankId: null,
};
