import React, { useRef } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { EditOutlined, PrinterOutlined } from '@ant-design/icons';
import { Div, H5, CRDivider } from 'components';
import { createDescription } from 'services/medicine.service';
import { useConfigurations } from 'hooks';
import ReactToPrint from 'react-to-print';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useHistory } from 'react-router-dom';
import { formatDate } from 'utils/date';
import { FULL_DAY_FORMAT } from 'utils/constants';

const HEIGHT = 'content-fit';

const ContainerStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: grey;
  position: relative;
`;

const WrapperStyled = styled(Div)`
  width: 500px;
  /* height: calc(100% - 40px); */
  height: ${HEIGHT};
  border: 1px solid black;
  border-radius: 8px;
  background: #ffffff;
`;

const Prescriptions = ({ prescriptions, onDeletePrescriptions }) => {
  const { pageSetupData } = useConfigurations();
  const history = useHistory();
  const ref = useRef();
  const pageSetupRow = pageSetupData.find(
    element => element.type === 'prescription'
  );

  // const margins = useMemo(
  //   () =>
  //     [
  //       { name: 'top', prop: 'pt' },
  //       { name: 'right', prop: 'pr' },
  //       { name: 'bottom', prop: 'pb' },
  //       { name: 'left', prop: 'pl' },
  //     ].reduce(
  //       (acc, { name, prop }) => ({
  //         ...acc,
  //         [prop]: (pageSetupRow[name] || 0) * 37.7952755906 + 'px',
  //       }),
  //       {}
  //     ),
  //   [pageSetupRow]
  // );

  const PrescriptionPage = ({ prescription, appId, index, date }) => {
    const printRef = useRef();
    return (
      <Div key={index} height={HEIGHT} p={4}>
        <Div>
          <Div>
            <ReactToPrint
              trigger={() => (
                <PrinterOutlined style={{ marginRight: '10px' }} />
              )}
              content={() => printRef.current}
            />
            <EditOutlined
              onClick={() => history.push(`/appointments/${appId}`)}
            />
            <CRDivider />
          </Div>
          <Div
            ref={printRef}
            mt={pageSetupRow?.top * 37.7952755906 || 0}
            mr={pageSetupRow?.right * 37.7952755906 || 0}
            mb={pageSetupRow?.bottom * 37.7952755906 || 0}
            ml={pageSetupRow?.left * 37.7952755906 || 0}
          >
            <Div>{date}</Div>
            <CRDivider />
            {prescription.map(({ medicine, dose }, idx) => (
              <Div key={idx}>
                <H5 mb={0}>{createDescription(medicine)}</H5>
                <Div>{dose}</Div>
                <CRDivider />
              </Div>
            ))}
          </Div>
        </Div>
      </Div>
    );
  };
  return (
    <ContainerStyled>
      <WrapperStyled ref={ref}>
        <Slider dots={null}>
          {prescriptions.map(({ appId, prescription, date }, index) => (
            <PrescriptionPage
              prescription={prescription}
              appId={appId}
              index={index}
              date={formatDate(date, FULL_DAY_FORMAT)}
            />
          ))}
        </Slider>
      </WrapperStyled>
    </ContainerStyled>
  );
};

Prescriptions.defaultProps = {
  prescriptions: [],
};

export default Prescriptions;
