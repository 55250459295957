export const approximatlyToTwoNumbers = number => {
  return Math.round((number + Number.EPSILON) * 100) / 100;
};

export function findDetails(data, query) {
  const branch = data.find(item => item.id === query.branch);

  if (!branch) {
    return { branchName: '', specialtyName: '', doctorName: '' };
  }

  if (query.specialty) {
    const specialty = branch.specialties.find(
      spec => spec.id === query.specialty
    );

    if (!specialty) {
      return { branchName: branch.name, specialtyName: '', doctorName: '' };
    }

    if (query.doctor) {
      const doctor = specialty.doctors.find(doc => doc.id === query.doctor);

      if (!doctor) {
        return {
          branchName: branch.name,
          specialtyName: specialty.name,
          doctorName: '',
        };
      }
      return {
        branchName: branch.name,
        specialtyName: specialty.name,
        doctorName: doctor.name,
      };
    }
    return {
      branchName: branch.name,
      specialtyName: specialty.name,
      doctorName: '',
    };
  }
  return { branchName: branch.name, specialtyName: '', doctorName: '' };
}
