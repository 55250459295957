import { useState, useCallback } from 'react';
import { CRButton } from 'components';
import { useInventory } from 'hooks';
import { useTranslation } from 'react-i18next';
import { getPdfReport } from 'services/reports';
import Filter from './filter';
import ListHistory from './list-history';
import useGlobalState from 'state';

const initalValue = {
  itemId: null,
};
const inialCurrentPage = {
  activePage: 1,
};

const InventoryHistory = () => {
  const [formValue, setFormValue] = useState(initalValue);
  const [period, setPeriod] = useState([]);
  const [currentPage, setCurrentPage] = useState(inialCurrentPage);
  const [user] = useGlobalState('user');

  const { history, items, inventoryPages } = useInventory({
    itemId: formValue?.itemId,
    dateFrom: period[0],
    dateTo: period[1],
    page: currentPage?.activePage,
  });
  const { t } = useTranslation();
  const handlePrint = useCallback(() => {
    const params = {
      itemId: formValue?.itemId,
      dateFrom: period[0],
      dateTo: period[1],
      organizationId: user.organizationId,
    };
    getPdfReport('/inventory/history', params, 'inventory-history.pdf');
  }, [formValue?.itemId, period, user]);
  return (
    <>
      <div className="flex items-center justify-between">
        <h1 className="text-2xl mb-4">{t('history')}</h1>

        {/* <CRButton onClick={handlePrint}>Print</CRButton> */}
      </div>
      <Filter
        formValue={formValue}
        setFormValue={setFormValue}
        setPeriod={setPeriod}
        t={t}
        items={items}
      />
      <ListHistory
        history={history}
        t={t}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        pages={inventoryPages}
      />
    </>
  );
};

export default InventoryHistory;
