import React from 'react';
import { CRModal, Div } from 'components';
import { CRCheckBox, CRTextArea } from 'components/widgets';
import { Form } from 'rsuite';
import { Can } from 'components/user/can';

const BusinessNotes = ({
  appointment,
  show,
  onCancel,
  onOk,
  notes,
  setNotes,
  t,
}) => {
  return (
    <CRModal
      show={show}
      header={t('addNotesOrAddCallHistory')}
      onOk={onOk}
      onHide={onCancel}
      onCancel={onCancel}
    >
      <Form formValue={notes} onChange={setNotes}>
        <CRTextArea name="businessNotes" />
        <Div display="flex">
          <CRCheckBox
            name="addToNotes"
            defaultChecked
            onChange={(val, checked) =>
              setNotes({ ...notes, addToNotes: checked })
            }
          >
            Add To Notes
          </CRCheckBox>
          <Can I="ViewCallHistory" an="Patient">
            <CRCheckBox
              name="addToCallHistory"
              onChange={(val, checked) =>
                setNotes({ ...notes, addToCallHistory: checked })
              }
            >
              Add To Call History
            </CRCheckBox>
          </Can>
        </Div>
      </Form>
    </CRModal>
  );
};

export default BusinessNotes;
